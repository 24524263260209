// @flow

import * as React from 'react';
import _ from 'lodash';

import { detectIE } from 'common/utils';
import { CAN_USE_DOM } from 'common/constants';

import { Responsive } from 'client/components/common/Responsive';
import { Icon } from '../Icon';

import * as css from './AccessibilityIcon.scss';

type Props = {
	onClick?: Function,
};

class AccessibilityIcon extends React.Component<Props> {
	static defaultProps = {
		onClick: _.noop,
	};

	// eslint-disable-next-line class-methods-use-this
	get accessablityBtn() {
		if (!CAN_USE_DOM) {
			return null;
		}

		return document.querySelector('#INDmenu-btn');
	}

	onClick = (event: SyntheticEvent<*>) => {
		const { onClick } = this.props;

		if (this.accessablityBtn) {
			this.accessablityBtn.click();
		}
		if (onClick) {
			onClick(event);
		}
	};

	onMouseEnter = () => {
		let eventFocus;

		if (!CAN_USE_DOM) {
			return;
		}

		if (Responsive.isMatching(Responsive.DESKTOP)) {
			if (detectIE() > 0) {
				eventFocus = document.createEvent('Event');
				eventFocus.initEvent('focus', false, true);
			} else {
				eventFocus = new Event('focus');
			}

			if (this.accessablityBtn) {
				this.accessablityBtn.dispatchEvent(eventFocus);
			}
		}
	};

	onMouseLeave = () => {
		let eventBlur;

		if (!CAN_USE_DOM) {
			return;
		}

		if (detectIE() > 0) {
			eventBlur = document.createEvent('Event');
			eventBlur.initEvent('blur', false, true);
		} else {
			eventBlur = new Event('blur');
		}

		if (this.accessablityBtn) {
			this.accessablityBtn.dispatchEvent(eventBlur);
		}
	};

	render() {
		const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
		return (
			<button
				type="button"
				onClick={this.onClick}
				onMouseEnter={this.onMouseEnter}
				onMouseLeave={this.onMouseLeave}
				className={css.button}
			>
				<Icon type="accessibility" width={!isDesktop ? 43 : 34} height={!isDesktop ? 43 : 34} />
			</button>
		);
	}
}

export default AccessibilityIcon;
